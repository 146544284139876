import { supabase } from "../config";

export const listBuckets = async () => {
  try {
    const { data, error } = await supabase.storage.listBuckets();
    if (error) throw new Error("No Buckets ", error);
    return data;
  } catch (error) {
    console.log("Ocurrio un error: ", error);
  }
};

export const listFilesFromBucket = async (bucketName) => {
  try {
    const { data, error } = await supabase.storage.from(bucketName).list();
    if (error) throw new Error("No files ", error);
    return data;
  } catch (error) {
    console.log("Ocurrio un error: ", error);
  }
};
