import { Outlet } from "react-router-dom";
import Footer from "../components/store/footer/footer";
import Header from "../components/store/header/header";

export default function StoreLayout() {
  return (
    <>
      {/* {isLoading === true && (
              <div className="loader">
                <img alt="loader" src={Loader} />
              </div>
            )} */}
      <Header />
      <Outlet />
      <Footer />
    </>
  );
}
