import { useContext } from "react";
import { TranslatorContext } from "../context/Translator";

export const useDollar = () => {
  const { currentLanguage, c, n } = useContext(TranslatorContext);
  const priceOfDollarRd = JSON.parse(
    localStorage.getItem("dollarPrice")
  )?.price;

  const covertFromUsdToDopCurrency = (value) => {
    const valueFormate =
    currentLanguage.code === "es"
      ? c(value * priceOfDollarRd?.toFixed(2))
      : c(value);

      return valueFormate
  }

  const covertFromUsdToDop = (value) => {
    const valueFormate =
    currentLanguage.code === "es"
    ? parseInt(n(value * priceOfDollarRd?.toFixed(2)).replace(/,/g, "")).toLocaleString()
    : value

      return valueFormate
  }

//   console.log("Precio del dolar actual", priceOfDollarRd?.toFixed(2))

  return { covertFromUsdToDopCurrency, covertFromUsdToDop };
};
