import * as React from "react";

// CSS & SCSS
import "./assets/fonts/inter.css";
import "./assets/fonts/material.css";
import "./assets/fonts/icofont/icofont.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "./assets/sass/styles.scss";

// JS & COMPONENTS
import "./index.css";
import "./i18n";
import { createRoot } from "react-dom/client";
import { ThemeProvider } from "./context/Themes";
import { SidebarProvider } from "./context/Sidebar";
import { LoaderProvider } from "./context/Preloader";
import { DollarPriceProvider } from "./context/Dollar";
import { TranslatorProvider } from "./context/Translator";
import { StoreProvider } from "./context/Store";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { LoginPage, RegisterPage, ForgotPasswordPage } from "./pages/auth";
import {
  // OverviewPage,
  DocumentationPage,
  ChangeLogPage,
  ErrorPage,
} from "./pages/others";
import {
  AvatarPage,
  AlertPage,
  ButtonPage,
  ChartPage,
  TablePage,
  FieldPage,
  HeadingPage,
  ColorPage,
} from "./pages/ui";
import {
  EcommercePage,
  AnalyticsPage,
  CRMPage,
  UserListPage,
  UserProfilePage,
  MyAccountPage,
  ProductListPage,
  ProductViewPage,
  ProductUploadPage,
  InvoiceListPage,
  InvoiceDetailsPage,
  OrderListPage,
  MessagePage,
  NotificationPage,
  BlankPage,
  SettingsPage,
} from "./pages/main";

import Home from "./pages/store/Home";
import Listing from "./pages/store/Listing";
import DetailsPage from "./pages/store/Details";
import Cart from "./pages/store/Cart";
import SignIn from "./pages/store/SignIn";
import SignUp from "./pages/store/SignUp";
import Checkout from "./pages/store/Checkout";
import NotFound from "./pages/store/NotFound";
import data from "./data";
import StoreLayout from "./layouts/StoreLayout";

const router = createBrowserRouter([
  // MAIN PAGES
  { path: "/ecommerce", element: <EcommercePage /> },
  { path: "/analytics", element: <AnalyticsPage /> },
  { path: "/crm", element: <CRMPage /> },
  { path: "/forgot-password", element: <ForgotPasswordPage /> },
  { path: "/register", element: <RegisterPage /> },
  { path: "/login", element: <LoginPage /> },
  { path: "/user-list", element: <UserListPage /> },
  { path: "/user-profile", element: <UserProfilePage /> },
  { path: "/my-account", element: <MyAccountPage /> },
  { path: "/product-list", element: <ProductListPage /> },
  { path: "/product-view", element: <ProductViewPage /> },
  { path: "/product-upload", element: <ProductUploadPage /> },
  { path: "/invoice-list", element: <InvoiceListPage /> },
  { path: "/invoice-details", element: <InvoiceDetailsPage /> },
  { path: "/order-list", element: <OrderListPage /> },
  { path: "/message", element: <MessagePage /> },
  { path: "/notification", element: <NotificationPage /> },
  { path: "/blank-page", element: <BlankPage /> },
  { path: "/settings", element: <SettingsPage /> },

  // UI PAGES
  { path: "/avatars", element: <AvatarPage /> },
  { path: "/alerts", element: <AlertPage /> },
  { path: "/buttons", element: <ButtonPage /> },
  { path: "/charts", element: <ChartPage /> },
  { path: "/tables", element: <TablePage /> },
  { path: "/fields", element: <FieldPage /> },
  { path: "/headings", element: <HeadingPage /> },
  { path: "/colors", element: <ColorPage /> },

  // OTHER PAGES
  { path: "/dashboard", element: <EcommercePage /> },
  { path: "/documentation", element: <DocumentationPage /> },
  { path: "/changelog", element: <ChangeLogPage /> },
  { path: "/error", element: <ErrorPage /> },

  {
    element: <StoreLayout />,
    children: [
      {
        path: "/",
        element: <Home data={data.productData} />,
      },
      {
        path: "/:id",
        element: <Listing data={data.productData} single={true} />,
      },
      {
        path: "/:id/:id",
        element: <Listing data={data.productData} single={false} />,
      },
      {
        path: "/product/:id",
        element: <DetailsPage data={data.productData} />,
      },
      {
        path: "/cart",
        element: <Cart />,
      },
      {
        path: "/signIn",
        element: <SignIn />,
      },
      {
        path: "/signUp",
        element: <SignUp />,
      },
      {
        path: "/checkout",
        element: <Checkout />,
      },
      {
        path: "*",
        element: <NotFound />,
      },
    ],
  },
]);

createRoot(document.getElementById("root")).render(
  <ThemeProvider>
    <LoaderProvider>
      <TranslatorProvider>
        <DollarPriceProvider>
          <SidebarProvider>
            <StoreProvider>
              <RouterProvider router={router} />
            </StoreProvider>
          </SidebarProvider>
        </DollarPriceProvider>
      </TranslatorProvider>
    </LoaderProvider>
  </ThemeProvider>
);
