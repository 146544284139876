import React from "react";
import "./footer.css";

import Icon1 from "../../../assets/store/images/icon-1.svg";
import Icon2 from "../../../assets/store/images/icon-2.svg";
import Icon3 from "../../../assets/store/images/icon-3.svg";
import Icon4 from "../../../assets/store/images/icon-4.svg";
import Icon5 from "../../../assets/store/images/icon-5.svg";
import { Link } from "react-router-dom";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import HeadphonesOutlinedIcon from "@mui/icons-material/HeadphonesOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import WatchLaterOutlinedIcon from "@mui/icons-material/WatchLaterOutlined";
import paymentImage from "../../../assets/store/images/payment-method.png";

import appStore from "../../../assets/store/images/app-store.jpg";
import googlePlay from "../../../assets/store/images/google-play.jpg";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";
import Newsletter from "../newsletter/index";
import NewsletterImg from "../../../assets/store/images/newsletter.png";

import diaspoLogo from "../../../assets/store/diaspo-logo.png";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 part1">
              <div className="logo  mb-30">
                <Link href="/" className="mb-15">
                  <img alt="logo" src={diaspoLogo} className="w-50" />
                  <h2 style={{ color: "#000" }}>Diaspo Wholesale Varienties</h2>
                </Link>
              </div>

              <p>
                <EmailOutlinedIcon /> <strong>Email: </strong>
                diaspowholesalev@gmail.com
              </p>
            </div>

            <div className="col-md-6 part2">
              <div className="row">
                <div className="col">
                  <h3>Company</h3>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                    <li>
                      <Link to="#">About Us</Link>
                    </li>
                    <li>
                      <Link to="#">Delivery Information</Link>
                    </li>
                    <li>
                      <Link to="#">Privacy Policy</Link>
                    </li>
                    <li>
                      <Link to="#">Terms &amp; Conditions</Link>
                    </li>
                    <li>
                      <Link to="#">Contact Us</Link>
                    </li>
                    <li>
                      <Link to="#">Support Center</Link>
                    </li>
                  </ul>
                </div>

                <div className="col">
                  <h3>Account</h3>
                  <ul className="footer-list mb-sm-5 mb-md-0">
                    <li>
                      <Link to="#">Sign In</Link>
                    </li>
                    <li>
                      <Link to="#">View Cart</Link>
                    </li>
                    <li>
                      <Link to="#">My Wishlist</Link>
                    </li>
                    <li>
                      <Link to="#">Track My Order</Link>
                    </li>
                    <li>
                      <Link to="#">Help Ticket</Link>
                    </li>
                    <li>
                      <Link to="#">Shipping Details</Link>
                    </li>
                    <li>
                      <Link to="#">Compare products</Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="col-md-3 part3">
              <p>Secured Payment Gateways</p>
              <img src={paymentImage} />
            </div>
          </div>

          <hr />

          <div className="row lastStrip">
            <div className="col-md-3 part_1">
              <p>© 2024, Diaspo Wholesale Varienties All rights reserved</p>
            </div>

            <div className="col-md-3 part3  part_3">
              <div className="d-flex align-items-center">
                <h5>Follow Us</h5>
                <ul className="list list-inline">
                  <li className="list-inline-item">
                    <Link to={""}>
                      <FacebookOutlinedIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={""}>
                      <TwitterIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={""}>
                      <InstagramIcon />
                    </Link>
                  </li>
                  <li className="list-inline-item">
                    <Link to={""}>
                      <YouTubeIcon />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Footer;
