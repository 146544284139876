import IconCompare from "./images/icon-compare.svg";
import IconHeart from "./images/icon-heart.svg";
import IconCart from "./images/icon-cart.svg";
import IconUser from "./images/icon-user.svg";

export const icons = {
  iconCompare: IconCompare,
  iconHeart: IconHeart,
  iconCart: IconCart,
  iconUser: IconUser,
};
