import React, { createContext, useState, useEffect } from "react";

const API_KEY = "29a77925ad-91c4835ce5-sb1mek";

const DollarPriceContext = createContext({
  dollarPrice: null,
  loading: true,
  error: null,
  fetchDollarPrice: async () => {},
});

const DollarPriceProvider = ({ children }) => {
  const [dollarPrice, setDollarPrice] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const fetchDollarPrice = async () => {
    setLoading(true);
    setError(null);

    const options = { method: "GET", headers: { accept: "application/json" } };

    try {
      const url = `https://api.fastforex.io/fetch-one?from=USD&to=DOP&api_key=${API_KEY}`;
      const response = await fetch(url, options);
      const data = await response.json();
      const dollarPrice = data.result.DOP;
      setDollarPrice(dollarPrice);

      // Actualizar el almacenamiento local solo si la obtención de datos es exitosa
      const expirationTime = new Date().getTime() + 86400000; // Expira en 24 horas
      localStorage.setItem(
        "dollarPrice",
        JSON.stringify({ price: dollarPrice, expirationTime })
      );
    } catch (error) {
      setError(error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    // Comprobar si hay un precio almacenado inicialmente
    const storedPriceData = JSON.parse(localStorage.getItem("dollarPrice"));

    if (storedPriceData && !isExpired(storedPriceData)) {
      // Si hay un precio almacenado y no está vencido, establecerlo
      setDollarPrice(storedPriceData?.price);
    } else {
      // Si no hay precio almacenado o está vencido, obtenerlo de la API
      fetchDollarPrice();
    }

    // Configurar el intervalo para obtener el precio cada 24 horas
    const intervalId = setInterval(fetchDollarPrice, 86400000);

    // Limpiar el intervalo al desmontar el componente
    return () => clearInterval(intervalId);
  }, []);

  const isExpired = (storedData) => {
    const now = new Date();
    return now.getTime() > storedData.expirationTime;
  };

  const contextValue = {
    dollarPrice,
    loading,
    error,
    fetchDollarPrice,
  };

  return (
    <DollarPriceContext.Provider value={contextValue}>
      {children}
    </DollarPriceContext.Provider>
  );
};

export { DollarPriceContext, DollarPriceProvider };
