import React, { useState, useEffect, useRef } from "react";
import "../header/header.css";
// import Logo from '../../../assets/store/images/logo.svg';
import SearchIcon from "@mui/icons-material/Search";
import Select from "../selectDrop/select";
import { LanguageOutlined } from "@mui/icons-material";
import LocationOnOutlinedIcon from "@mui/icons-material/LocationOnOutlined";
import { icons } from "../../../assets/store/icons";
import Button from "@mui/material/Button";
import Person2OutlinedIcon from "@mui/icons-material/Person2Outlined";
import FavoriteBorderOutlinedIcon from "@mui/icons-material/FavoriteBorderOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";

import { ClickAwayListener } from "@mui/base/ClickAwayListener";

import Nav from "./nav/nav";
import { useContext } from "react";

import { StoreContext } from "../../../context/Store";
import { Link, useNavigate } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";

import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";

import { Menu, MenuItem, Modal, Box } from "@mui/material";
import diaspoLogo from "../../../assets/store/diaspo-logo.png";
import item from "../../../assets/store/products/women-high.jpg";
import data from "../../../data";
const Header = () => {
  const props = {
    data: data,
  };
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleDropdownClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleDropdownClose = () => {
    setAnchorEl(null);
  };
  const [showModal, setShowModal] = useState(false);

  const toggleModal = () => setShowModal(!showModal);

  const [isOpenDropDown, setisOpenDropDown] = useState(false);
  const [isOpenAccDropDown, setisOpenAccDropDown] = useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [isopenSearch, setOpenSearch] = useState(false);
  const [isOpenNav, setIsOpenNav] = useState(false);

  const headerRef = useRef();
  const searchInput = useRef();

  const context = useContext(StoreContext);
  // const history = useNavigate();
  useEffect(() => {}, [context.cartItems]);

  const [categories, setCategories] = useState([
    "Ropa para Hombre",
    "Ropa para Mujer",
    "Ropa para Niños",
    "Accesorios",
    "Ropa Interior",
    "Ropa Deportiva",
    "Ropa Formal",
    "Ropa Casual",
    "Decoración para Interiores",
    "Decoración Exterior",
    "Arte y Fotografía",
    "Decoración de Navidad",
    "Decoración de Halloween",
    "Decoración de Cumpleaños",
    "Decoración de Eventos",
  ]);

  const selectLanguage = ["es", "en"];

  useEffect(() => {
    window.addEventListener("scroll", () => {
      let position = window.pageYOffset;
      if (position > 100) {
        headerRef.current.classList.add("fixed");
      } else {
        headerRef.current.classList.remove("fixed");
      }
    });
  }, []);

  const signOut = () => {
    context.signOut();
    // history("/");
  };

  const openSearch = () => {
    setOpenSearch(true);
    searchInput.current.focus();
  };

  const closeSearch = () => {
    setOpenSearch(false);
    searchInput.current.blur();
    searchInput.current.value = "";
  };

  const openNav = () => {
    setIsOpenNav(true);
    context.setIsopenNavigation(true);
  };

  const closeNav = () => {
    setIsOpenNav(false);
    setisOpenAccDropDown(false);
    context.setIsopenNavigation(false);
  };
  const [cart, setCart] = useState([
    {
      id: 1,
      name: "Cozy Blanket",
      price: 29.99,
      quantity: 1,
      image: "/placeholder.svg",
    },
    {
      id: 2,
      name: "Autumn Mug",
      price: 12.99,
      quantity: 2,
      image: "/placeholder.svg",
    },
    {
      id: 3,
      name: "Fall Fragrance Candle",
      price: 16.99,
      quantity: 1,
      image: "/placeholder.svg",
    },
  ]);
  const handleQuantityChange = (id, quantity) => {
    setCart(
      cart.map((item) => (item.id === id ? { ...item, quantity } : item))
    );
  };
  const handleRemoveItem = (id) => {
    setCart(cart.filter((item) => item.id !== id));
  };
  const total = cart.reduce((acc, item) => acc + item.price * item.quantity, 0);

  function MinusIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5 12h14" />
      </svg>
    );
  }

  function PlusIcon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M5 12h14" />
        <path d="M12 5v14" />
      </svg>
    );
  }

  function Trash2Icon(props) {
    return (
      <svg
        {...props}
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        stroke="currentColor"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      >
        <path d="M3 6h18" />
        <path d="M19 6v14c0 1-1 2-2 2H7c-1 0-2-1-2-2V6" />
        <path d="M8 6V4c0-1 1-2 2-2h4c1 0 2 1 2 2v2" />
        <line x1="10" x2="10" y1="11" y2="17" />
        <line x1="14" x2="14" y1="11" y2="17" />
      </svg>
    );
  }
  return (
    <>
      <div className="headerWrapper" ref={headerRef}>
        <header>
          <div className="container-fluid">
            <div className="row">
              <div className="col-sm-2 part1 d-flex align-items-center">
                <img alt="logo" src={diaspoLogo} className="w-50" />
                <div className="ml-auto d-flex align-items-center">
                  {/* <div className="navbarToggle mr-0" onClick={openSearch}>
                    <SearchIcon />
                  </div> */}
                  {/* <ul className="list list-inline mb-0 headerTabs pl-0 mr-4">
                    <li className="list-inline-item">
                      <span>
                        <Link to={"/cart"}>
                          <img src={icons.iconCart} />
                          <span className="badge bg-success rounded-circle">
                            {context.cartItems.length}
                          </span>
                        </Link>
                      </span>
                    </li>
                  </ul>
                  <div className="navbarToggle mr-2" onClick={openNav}>
                    <MenuIcon />
                  </div> */}
                  {/* {context.isLogin === "true" && (
                    <div
                      className="myAccDrop"
                      onClick={() => setisOpenAccDropDown(!isOpenAccDropDown)}
                    >
                      <PersonOutlineOutlinedIcon />
                    </div>
                  )} */}
                </div>
              </div>

              <div className="col-sm-5 part2">
                <div
                  className={`headerSearch d-flex align-items-center ${
                    isopenSearch === true ? "open" : ""
                  }`}
                >
                  {windowWidth < 992 && (
                    <div className="closeSearch" onClick={closeSearch}>
                      <ArrowBackIosIcon />
                    </div>
                  )}
                  <Select
                    data={categories}
                    placeholder={"Todas las categorias"}
                    icon={false}
                  />

                  <div className="search">
                    <input
                      type="text"
                      placeholder="Buscar articulos..."
                      ref={searchInput}
                    />
                    <SearchIcon className="searchIcon cursor" />
                  </div>
                </div>
              </div>

              <div className="col-sm-5 d-flex align-items-center part3 res-hide">
                <div className="ml-auto d-flex align-items-center">
                  <div className="countryWrapper">
                    <Select
                      data={selectLanguage}
                      placeholder={"Seleccionar idioma"}
                      icon={<LanguageOutlined style={{ opacity: "0.5" }} />}
                    />
                  </div>
                  <ClickAwayListener
                    onClickAway={() => setisOpenDropDown(false)}
                  >
                    <ul className="list list-inline mb-0 headerTabs">
                      <li className="list-inline-item">
                        <span>
                          <img src={icons.IconHeart} />
                          <span className="badge bg-success rounded-circle">
                            3
                          </span>
                          Lista de deseos
                        </span>
                      </li>

                      <li className="list-inline-item">
                        <span onClick={handleDropdownClick}>
                          <img src={icons.IconCart} />
                          <span className="badge bg-success rounded-circle">
                            {context.cartItems.length}
                          </span>
                          Carrito
                          <Menu
                            id="carrito-dropdown"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleDropdownClose}
                          >
                            {context.cartItems.map((item, index) => (
                              <MenuItem key={index}>{item.name}</MenuItem>
                            ))}
                            <MenuItem>
                              <div className="bg-background p-6 rounded-lg shadow-lg">
                                <div className="grid gap-6">
                                  <div className="grid gap-4">
                                    {cart.map((item) => (
                                      <div
                                        key={item.id}
                                        className="grid grid-cols-[80px_1fr_auto] items-center gap-4"
                                      >
                                        <img
                                          src="/placeholder.svg"
                                          alt={item.name}
                                          width={80}
                                          height={80}
                                          className="rounded-md object-cover"
                                        />
                                        <div className="grid gap-1">
                                          <h3 className="font-semibold">
                                            {item.name}
                                          </h3>
                                          <p className="text-muted-foreground text-sm">
                                            ${item.price.toFixed(2)}
                                          </p>
                                        </div>
                                        <div className="flex items-center gap-2">
                                          <Button
                                            variant="outline"
                                            size="icon"
                                            onClick={() =>
                                              handleQuantityChange(
                                                item.id,
                                                item.quantity - 1
                                              )
                                            }
                                            disabled={item.quantity === 1}
                                          >
                                            <MinusIcon className="w-4 h-4" />
                                          </Button>
                                          <span className="px-2">
                                            {item.quantity}
                                          </span>
                                          <Button
                                            variant="outline"
                                            size="icon"
                                            onClick={() =>
                                              handleQuantityChange(
                                                item.id,
                                                item.quantity + 1
                                              )
                                            }
                                          >
                                            <PlusIcon className="w-4 h-4" />
                                          </Button>
                                          <Button
                                            variant="ghost"
                                            size="icon"
                                            onClick={() =>
                                              handleRemoveItem(item.id)
                                            }
                                          >
                                            <Trash2Icon className="w-4 h-4 text-red-500" />
                                          </Button>
                                        </div>
                                      </div>
                                    ))}
                                  </div>
                                  <div className="flex flex-col items-end gap-4">
                                    <div className="text-right">
                                      <p className="text-muted-foreground">
                                        Total:
                                      </p>
                                      <p className="text-2xl font-bold">
                                        ${total.toFixed(2)}
                                      </p>
                                    </div>
                                    <div className="flex gap-2">
                                      <Button variant="outline">
                                        Continue Shopping
                                      </Button>
                                      <Button>Proceed to Checkout</Button>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </MenuItem>
                          </Menu>
                        </span>
                      </li>

                      {context.isLogin === "true" ? (
                        <li className="list-inline-item">
                          <span
                            onClick={() => setisOpenDropDown(!isOpenDropDown)}
                          >
                            <img src={icons.IconUser} />
                            Account
                          </span>

                          {isOpenDropDown !== false && (
                            <ul className="dropdownMenu">
                              <li>
                                <Button className="align-items-center">
                                  <Person2OutlinedIcon /> My Account
                                </Button>
                              </li>
                              <li>
                                <Button>
                                  <LocationOnOutlinedIcon /> Order Tracking
                                </Button>
                              </li>
                              <li>
                                <Button>
                                  <FavoriteBorderOutlinedIcon /> My Wishlist
                                </Button>
                              </li>
                              <li>
                                <Button>
                                  <SettingsOutlinedIcon /> Setting
                                </Button>
                              </li>
                              <li>
                                <Button onClick={signOut}>
                                  <LogoutOutlinedIcon /> Sign out
                                </Button>
                              </li>
                            </ul>
                          )}
                        </li>
                      ) : (
                        <li
                          className="list-inline-item"
                          onClick={() => navigate("/signIn")}
                        >
                          <Link to={"/signIn"}>
                            <Button className="btn btn-g">
                              Iniciar sesion
                            </Button>
                          </Link>
                        </li>
                      )}
                    </ul>
                  </ClickAwayListener>
                </div>
              </div>
            </div>
          </div>
        </header>

        <Nav data={props.data} openNav={isOpenNav} closeNav={closeNav} />
      </div>

      <div className="afterHeader"></div>

      {isOpenAccDropDown !== false && (
        <>
          <div className="navbarOverlay" onClick={closeNav}></div>
          <ul className="dropdownMenu dropdownMenuAcc" onClick={closeNav}>
            <li>
              <Button className="align-items-center">
                <Link to="">
                  <Person2OutlinedIcon /> My Account
                </Link>
              </Button>
            </li>

            <li>
              <Button className="align-items-center">
                <Link to="">
                  <img src={icons.IconCart} />
                  Cart
                </Link>
              </Button>
            </li>
            <li>
              <Button>
                <Link to="">
                  <LocationOnOutlinedIcon /> Order Tracking
                </Link>
              </Button>
            </li>
            <li>
              <Button>
                <Link to="">
                  <FavoriteBorderOutlinedIcon /> My Wishlist
                </Link>
              </Button>
            </li>
            <li>
              <Button>
                <Link to="">
                  <SettingsOutlinedIcon /> Setting
                </Link>
              </Button>
            </li>
            <li>
              <Button onClick={signOut}>
                <Link to="">
                  <LogoutOutlinedIcon /> Sign out
                </Link>
              </Button>
            </li>
          </ul>
        </>
      )}
    </>
  );
};

export default Header;
