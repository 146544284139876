import womenHigh from "../src/assets/store/products/women-high.jpg";
import fannyPack from "../src/assets/store/products/fanny-pack.jpg";
import hanesPremium from "../src/assets/store/products/hanes-premium.jpg";
import haneyWomen from "../src/assets/store/products/haney-women.jpg";
import womenCotton from "../src/assets/store/products/women-cotton.jpg";
import womenLong from "../src/assets/store/products/women-long.jpg";
import womenRelaxed from "../src/assets/store/products/women-relaxed.jpg";
import womenScoopFront from "../src/assets/store/products/women-scoop-front.jpg";
import womenScoop from "../src/assets/store/products/women-scoop.jpg";
import womenSide from "../src/assets/store/products/women-side.jpg";
import womenSleeve from "../src/assets/store/products/women-sleeve.jpg";
const data = {
  navigationData: [
    {
      path: "/",
      value: "Home",
    },
    {
      path: "/blog",
      value: "Blog",
    },
    {
      path: "/contact",
      value: "contact",
    },
  ],
  productData: [
    {
      cat_name: "Ropa",
      id: 2,
      image: "https://cdn-icons-png.flaticon.com/128/12516/12516451.png",
      items: [
        {
          cat_name: "Mujeres",
          products: [
            {
              productName:
                "Women's High-Rise Relaxed Fit Full Length Baggy Wide Leg Trousers - A New Day Black 16",
              price: "16,999.00",
              oldPrice: "19,999.00",

              ProductAvaliable: 0,
              catImg: womenHigh,
              discount: "12",
              brand: "Shein",
              productImages: [womenHigh],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 35,
            },
            {
              productName: "Women's Cotton Bodysuit - AudenTM Red XL",
              price: "56,900.00",
              oldPrice: "59,900.00",

              catImg: womenCotton,
              discount: "20",
              brand: "Shein",
              productImages: [womenCotton],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 36,
            },
            {
              productName:
                "Hanes Women's 10pk Cushioned No Show Socks — Black 5-9",
              price: "5,555.00",
              oldPrice: "7,999.00 ",
              catImg: haneyWomen,
              discount: "16",
              brand: "Zara",
              productImages: [haneyWomen],
              rating: 4.2,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 37,
            },
            {
              productName:
                "Women's Long Sleeve Slim Fit Crewneck T-Shirt-A New Da TMTea1 Blue",
              price: "10,299.00",
              oldPrice: "12,999.00 ",
              weight: [],
              catImg: womenLong,
              discount: "16",
              brand: "Zara",
              productImages: [womenLong],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 38,
            },
            {
              productName:
                "Women's Long Sleeve Slim Fit Crewneck T-Shirt- A New Da 'White M",
              price: "12,199.00",
              oldPrice: "16,999.00",
              weight: [],
              catImg: womenSleeve,
              discount: "10",
              brand: "Zara",
              productImages: [womenSleeve],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 39,
            },
          ],
        },
        {
          cat_name: "Hombres",
          products: [
            {
              productName:
                "Women's Side-Tie Cheeky High Leg Bikini Bottom - Wild Fable Peach",
              price: "9,990.00",
              oldPrice: "21,999.00 ",
              weight: [],
              catImg: womenSide,
              discount: "16",
              brand: "Zara",
              productImages: [womenSide],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 40,
            },
            {
              productName:
                "Women's Scoop Neck Sweater Tank Top - A New Day ' Cream S",
              price: "9,990.00",
              oldPrice: "21,999.00 ",
              weight: [],

              catImg: womenScoop,
              discount: "16",
              brand: "Zara",
              productImages: [womenScoop],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 41,
            },
            {
              productName:
                "Women's Relaxed Fit Blazer - Universal Thread Gra XS",
              price: "22,990.00",
              oldPrice: "33,999.00",
              weight: [],
              catImg: womenRelaxed,
              discount: "32",
              brand: "Zara",
              productImages: [womenRelaxed],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 42,
            },
            {
              productName:
                "Women's Scoop Front Low-Rise High Leg Cheeky Bikini Bottom - Wild Fable Purple",
              price: "39,990.00",
              oldPrice: "121,990.00",
              weight: [],
              catImg: womenScoopFront,
              discount: "32",
              brand: "Zara",
              productImages: [womenScoopFront],
              rating: 4,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 43,
            },
          ],
        },
      ],
    },
    {
      cat_name: "Hogar",
      id: 3,
      image: "https://cdn-icons-png.flaticon.com/128/3659/3659898.png",
      items: [
        {
          cat_name: "Decoraciones",
          products: [
            {
              productName: "Bomber Jacket with Zipper Pockets",
              price: "1,593.00",
              oldPrice: "3,125.00  ",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL", "XXL"],
              catImg:
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model-469255919_black-0-202301311905.jpg",
              discount: "49",
              brand: "Fort Collins",
              productImages: [
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model-469255919_black-0-202301311905.jpg",
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model2-469255919_black-2-202301311905.jpg",
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model3-469255919_black-3-202301311905.jpg",
                "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model4-469255919_black-4-202301311905.jpg",
              ],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 44,
            },
            {
              productName: "Mid-Rise Skinny Fit Jeans",
              ofectActive: false,
              price: "269.00",
              oldPrice: "899.00",
              weight: [],
              RAM: [],
              SIZE: [28, 30, 32, 34],
              catImg:
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model-420434311_blue-0-202308081627.jpg",
              discount: "49",
              brand: "Fort Collins",
              productImages: [
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model-420434311_blue-0-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model2-420434311_blue-2-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model3-420434311_blue-3-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model4-420434311_blue-4-202308081627.jpg",
                "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model5-420434311_blue-5-202308081627.jpg",
              ],
              rating: 5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 45,
            },
            {
              productName: "Plutus Men Black Solid Polyester Casual Jacket M",
              price: "499.00",
              oldPrice: "699.00",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL"],
              catImg:
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-0-202209240106.jpg",
              discount: "60",
              brand: "PLUTUS",
              productImages: [
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-0-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-1-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-2-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-3-202209240106.jpg",
                "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-4-202209240106.jpg",
              ],
              rating: 5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 46,
            },
            {
              productName:
                "BULLMER Men Beige Cotton Blend Printed Single Sweatshirts",
              price: "699.00",
              oldPrice: "989.00",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL"],
              catImg:
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-0-202210291839.jpg",
              discount: "60",
              brand: "BULLMER",
              productImages: [
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-0-202210291839.jpg",
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-1-202210291839.jpg",
                "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-2-202210291839.jpg",
              ],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 47,
            },
            {
              productName: "Men Checked Slim Fit Shirt",
              price: "179.00",
              oldPrice: "489.00",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL"],
              catImg:
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model-420395610_red-0-202309052055.jpg",
              discount: "45",
              brand: "ROXTAR",
              productImages: [
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model-420395610_red-0-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model2-420395610_red-2-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model3-420395610_red-3-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model4-420395610_red-4-202309052055.jpg",
                "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model5-420395610_red-5-202309052055.jpg",
              ],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 48,
            },
          ],
        },
        {
          cat_name: "Accesorios",
          products: [
            {
              productName: "Rudraaksha Stylish Dress For women",
              price: "299.00",
              oldPrice: "389.00",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL"],
              catImg:
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-0-202210042034.jpg",
              discount: "65",
              brand: "Rudraaksha",
              productImages: [
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-0-202210042034.jpg",
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-1-202210042034.jpg",
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-2-202210042034.jpg",
                "https://www.jiomart.com/images/product/original/rvxsvl3imx/rudraaksha-stylish-dress-for-women-product-images-rvxsvl3imx-3-202210042034.jpg",
              ],
              rating: 4.5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 49,
            },
            {
              productName:
                "Fabflee Party Flared Sleeves Printed Women Yellow Top | Women Tops | Crepe Top | Tops For Women",
              price: "499.00",
              oldPrice: "589.00",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL"],
              catImg:
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-0-202309260654.jpg",
              discount: "65",
              brand: "Rudraaksha",
              productImages: [
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-0-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-1-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-2-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-3-202309260654.jpg",
                "https://www.jiomart.com/images/product/original/rvnipawgyz/fabflee-party-flared-sleeves-printed-women-yellow-top-women-tops-crepe-top-tops-for-women-product-images-rvnipawgyz-4-202309260654.jpg",
              ],
              rating: 4,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 50,
            },
            {
              productName: "Shrug with Shawl Lapel",
              price: "699.00",
              oldPrice: "989.00",
              weight: [],
              RAM: [],
              SIZE: ["M", "L", "XL"],
              catImg:
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model-441131609_grey-0-202309271631.jpg",
              discount: "65",
              brand: "FIG",
              productImages: [
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model2-441131609_grey-2-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model3-441131609_grey-3-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model3-441131609_grey-3-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model4-441131609_grey-4-202309271631.jpg",
                "https://www.jiomart.com/images/product/original/441131609_grey/shrug-with-shawl-lapel-model5-441131609_grey-5-202309271631.jpg",
              ],
              rating: 5,
              description:
                "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
              id: 51,
            },
          ],
        },
      ],
    },
  ],
  productReviews: [
    {
      review: "nice ",
      userName: "rinku verma",
      rating: "5",
      productId: "47",
      date: "11/19/2023, 12:35:32 AM",
      id: 1,
    },
    {
      review: "good ",
      userName: "sfsdf",
      rating: "4",
      productId: "40",
      date: "11/19/2023, 12:36:30 AM",
      id: 2,
    },
    {
      review:
        "Realme C55 DLC 64GB, 4 GB RAM, Sunshower, Mobile Phone  nice product ",
      userName: "rinku verma",
      rating: "5",
      productId: "38",
      date: "11/19/2023, 12:37:35 AM",
      id: 3,
    },
    {
      review:
        "HARYANA FRESH Rich Desi Danedar Ghee 500 Grams (Carton)  nice product ",
      userName: "@rinkuv37",
      rating: "5",
      productId: "13",
      date: "11/19/2023, 6:54:23 PM",
      id: 4,
    },
    {
      review: "nice product ",
      userName: "rinku verma",
      rating: "5",
      productId: "36",
      date: "11/19/2023, 8:43:56 PM",
      id: 5,
    },
    {
      review: "zsfzsdf",
      userName: "dfsdff",
      rating: "5",
      productId: "8",
      date: "11/22/2023, 6:57:23 PM",
      id: 6,
    },
    {
      review: "",
      userName: "",
      rating: 0,
      productId: 0,
      date: "",
      id: 7,
    },
    {
      review: "nice product",
      userName: "rinku verma",
      rating: 0,
      productId: "1",
      date: "12/17/2023, 5:58:25 AM",
      id: 8,
    },
    {
      review: "nice product",
      userName: "rinku verma",
      rating: "5",
      productId: "1",
      date: "12/17/2023, 5:58:43 AM",
      id: 9,
    },
    {
      review: "nice product ",
      userName: "rinku verma",
      rating: "5",
      productId: "36",
      date: "12/17/2023, 10:18:11 PM",
      id: 10,
    },
  ],
  cartItems: [
    {
      productName: "Moong Dal 2 kg",
      price: "246",
      oldPrice: "320",
      weight: [50, 100, 150, 200, 250, 500],
      catImg:
        "https://www.jiomart.com/images/product/original/491432711/moong-dal-2-kg-product-images-o491432711-p491432711-0-202205191636.jpg",
      discount: "16",
      brand: "Private Label",
      productImages: [
        "https://www.jiomart.com/images/product/original/491432711/moong-dal-2-kg-product-images-o491432711-p491432711-0-202205191636.jpg",
        "https://www.jiomart.com/images/product/original/491432711/moong-dal-2-kg-product-images-o491432711-p491432711-1-202205191636.jpg",
        "https://www.jiomart.com/images/product/original/491432711/moong-dal-2-kg-legal-images-o491432711-p491432711-3-202205191636.jpg",
      ],
      rating: 4,
      description:
        "Moong Dal is essentially packed with loads of nutrients that are extremely beneficial for your health. Moong contains almost every important element that your body might need. Buy Moong Dal online today.",
      id: 2,
      quantity: 1,
    },
    {
      productName: "BULLMER Men Beige Cotton Blend Printed Single Sweatshirts",
      price: "699.00",
      oldPrice: "989.00",
      weight: [],
      RAM: [],
      SIZE: ["M", "L", "XL"],
      catImg:
        "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-0-202210291839.jpg",
      discount: "60",
      brand: "BULLMER",
      productImages: [
        "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-0-202210291839.jpg",
        "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-1-202210291839.jpg",
        "https://www.jiomart.com/images/product/original/rvqq9gohkt/bullmer-men-beige-cotton-blend-printed-single-sweatshirts-product-images-rvqq9gohkt-2-202210291839.jpg",
      ],
      rating: 4.5,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      id: 47,
      parentCatName: "Fashion",
      subCatName: "Men Western Wear",
      quantity: 1,
    },
    {
      productName: "Good Life Moong Dal 1 kg",
      price: "145",
      oldPrice: "189",
      weight: [50, 100, 150, 200, 250, 500],
      catImg:
        "https://www.jiomart.com/images/product/original/491187257/good-life-moong-dal-1-kg-product-images-o491187257-p491187257-0-202301171616.jpg",
      discount: "12",
      brand: "Good Life",
      productImages: [
        "https://www.jiomart.com/images/product/original/491187257/good-life-moong-dal-1-kg-product-images-o491187257-p491187257-0-202301171616.jpg",
        "https://www.jiomart.com/images/product/original/491187257/good-life-moong-dal-1-kg-product-images-o491187257-p491187257-1-202301171616.jpg",
        "https://www.jiomart.com/images/product/original/491187257/good-life-moong-dal-1-kg-legal-images-o491187257-p491187257-2-202301171616.jpg",
      ],
      rating: 4.5,
      description:
        "Good life Moong Dal is procured from reliable and high-quality sources. The beans are best consumed after being sprouted. Dosa with whole moong also turns out to be yummy, another exotic way to eat moong is to eat it as a korma which is spiced up with tangy tomatoes and a cashew paste. It is mainly used as the base for curries such as sambar, dal etc. Buy Good Life Moong Dal online now!",
      id: 5,
      quantity: 1,
    },
    {
      productName: "Bomber Jacket with Zipper Pockets",
      price: "1,593.00",
      oldPrice: "3,125.00  ",
      weight: [],
      RAM: [],
      SIZE: ["M", "L", "XL", "XXL"],
      catImg:
        "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model-469255919_black-0-202301311905.jpg",
      discount: "49",
      brand: "Fort Collins",
      productImages: [
        "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model-469255919_black-0-202301311905.jpg",
        "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model2-469255919_black-2-202301311905.jpg",
        "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model3-469255919_black-3-202301311905.jpg",
        "https://www.jiomart.com/images/product/original/469255919_black/bomber-jacket-with-zipper-pockets-model4-469255919_black-4-202301311905.jpg",
      ],
      rating: 4.5,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      id: 44,
      quantity: 1,
    },
    {
      productName: "Mid-Rise Skinny Fit Jeans",
      price: "269.00",
      oldPrice: "899.00",
      weight: [],
      RAM: [],
      SIZE: [28, 30, 32, 34],
      catImg:
        "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model-420434311_blue-0-202308081627.jpg",
      discount: "49",
      brand: "Fort Collins",
      productImages: [
        "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model-420434311_blue-0-202308081627.jpg",
        "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model2-420434311_blue-2-202308081627.jpg",
        "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model3-420434311_blue-3-202308081627.jpg",
        "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model4-420434311_blue-4-202308081627.jpg",
        "https://www.jiomart.com/images/product/original/420434311_blue/skinny-jeans-with-button-closure-model5-420434311_blue-5-202308081627.jpg",
      ],
      rating: 5,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      id: 45,
      quantity: 1,
    },
    {
      productName: "Plutus Men Black Solid Polyester Casual Jacket M",
      price: "499.00",
      oldPrice: "699.00",
      weight: [],
      RAM: [],
      SIZE: ["M", "L", "XL"],
      catImg:
        "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-0-202209240106.jpg",
      discount: "60",
      brand: "PLUTUS",
      productImages: [
        "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-0-202209240106.jpg",
        "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-1-202209240106.jpg",
        "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-2-202209240106.jpg",
        "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-3-202209240106.jpg",
        "https://www.jiomart.com/images/product/original/rvurcwrojo/plutus-men-black-solid-polyester-casual-jacket-m-product-images-rvurcwrojo-4-202209240106.jpg",
      ],
      rating: 5,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      id: 46,
      quantity: 1,
    },
    {
      productName: "Men Checked Slim Fit Shirt",
      price: "179.00",
      oldPrice: "489.00",
      weight: [],
      RAM: [],
      SIZE: ["M", "L", "XL"],
      catImg:
        "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model-420395610_red-0-202309052055.jpg",
      discount: "45",
      brand: "ROXTAR",
      productImages: [
        "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model-420395610_red-0-202309052055.jpg",
        "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model2-420395610_red-2-202309052055.jpg",
        "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model3-420395610_red-3-202309052055.jpg",
        "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model4-420395610_red-4-202309052055.jpg",
        "https://www.jiomart.com/images/product/original/420395610_red/checked-slim-fit-shirt-model5-420395610_red-5-202309052055.jpg",
      ],
      rating: 4.5,
      description:
        "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. ",
      id: 48,
      quantity: 1,
    },
    {
      productName: "Good Life Whole Moong 500 g",
      price: "20",
      oldPrice: "140",
      weight: [50, 100, 150, 200, 250, 500],
      catImg:
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-0-202308311426.jpg",
      discount: "12",
      brand: "AASHIRVAAD",
      productImages: [
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-0-202308311426.jpg",
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-1-202308311426.jpg",
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-2-202308311426.jpg",
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-3-202308311426.jpg",
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-product-images-o491187309-p491187309-4-202308311426.jpg",
        "https://www.jiomart.com/images/product/original/491187309/good-life-whole-moong-500-g-legal-images-o491187309-p491187309-5-202308311427.jpg",
      ],
      rating: 3.5,
      description:
        "Good Life Whole Moong is one of the light meal to digest. It is often given to people who prefer diet for fitness. It contains antioxidants which are very healthy for the good functioning of the human body. It can be included in our daily diet as it can fulfil the nutritional and dietary requirements of an entire meal. Buy Good Life Whole Moong online now!",
      id: 1,
      parentCatName: "groceries",
      subCatName: "dals and pulses",
      quantity: 1,
    },
  ],
};

export default data;
