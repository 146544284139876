import React, { useState, useEffect, useRef, useContext } from "react";
import SliderBanner from "./slider/index";
import CatSlider from "../../../components/store/catSlider";

import Banners from "../../../components/store/banners";

import "./style.css";
import Product from "../../../components/store/product";
import Banner4 from "../../../assets/store/images/banner4.jpg";

import Slider from "react-slick";
import TopProducts from "./TopProducts";
import { StoreContext } from "../../../context/Store";

const Home = (props) => {
  const [prodData, setprodData] = useState(props.data);
  const [catArray, setcatArray] = useState([]);
  const [activeTab, setactiveTab] = useState();
  const [activeTabIndex, setactiveTabIndex] = useState(0);
  const [activeTabData, setActiveTabData] = useState([]);

  const [bestSells, setBestSells] = useState([]);
  const [isLoadingProducts, setIsLoadingProducts] = useState(false);

  const productRow = useRef();
  const context = useContext(StoreContext);
  var settings = {
    dots: false,
    infinite: context.windowWidth < 992 ? false : true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    fade: false,
    arrows: context.windowWidth < 992 ? false : true,
  };

  useEffect(() => {
    // Function to create the catArr array
    const createCatArr = () => {
      const catArr = [];
      prodData.length !== 0 &&
        prodData.forEach((item) => {
          item.items.length !== 0 &&
            item.items.forEach((item_) => {
              catArr.push(item_.cat_name);
            });
        });

      // Remove duplicates efficiently using Set
      const uniqueCatArr = [...new Set(catArr)];
      return uniqueCatArr;
    };

    const catArr = createCatArr(); // Call the function to create catArr

    setcatArray(catArr);
    setactiveTab(catArr[0]);

    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    var arr = [];
    setActiveTabData(arr);
    prodData.length !== 0 &&
      prodData.map((item, index) => {
        item.items.map((item_, index_) => {
          if (item_.cat_name === activeTab) {
            item_.products.length !== 0 &&
              item_.products.map((product) => {
                arr.push({
                  ...product,
                  parentCatName: item.cat_name,
                  subCatName: item_.cat_name,
                });
              });

            setActiveTabData(arr);
            setTimeout(() => {
              setIsLoadingProducts(false);
            }, [1000]);
          }
        });
      });
  }, [activeTab]);

  const bestSellsArr = [];

  useEffect(() => {
    prodData.length !== 0 &&
      prodData.map((item) => {
        if (item.cat_name === "Electronics") {
          item.items.length !== 0 &&
            item.items.map((item_) => {
              item_.products.length !== 0 &&
                item_.products.map((product, index) => {
                  bestSellsArr.push(product);
                });
            });
        }
      });

    setBestSells(bestSellsArr);
  }, []);

  return (
    <div style={{ display: "block" }}>
      <SliderBanner />
      <CatSlider data={prodData} />

      <Banners />

      <section className="homeProducts homeProductWrapper">
        <div className="container-fluid">
          <div className="d-flex align-items-center homeProductsTitleWrap">
            <h2 className="hd mb-0 mt-0 res-full">Productos Populares</h2>
            <ul className="list list-inline ml-auto filterTab mb-0 res-full">
              {catArray.length !== 0 &&
                catArray.map((cat, index) => {
                  return (
                    <li key={index + 10} className="list list-inline-item">
                      <div
                        className={`cursor text-capitalize ${
                          activeTabIndex === index ? "act" : ""
                        }`}
                        onClick={() => {
                          setactiveTab(cat);
                          setactiveTabIndex(index);
                          productRow.current.scrollLeft = 0;
                          setIsLoadingProducts(true);
                        }}
                      >
                        {cat}
                      </div>
                    </li>
                  );
                })}
            </ul>
          </div>

          <div
            className={`productRow ${isLoadingProducts === true && "loading"}`}
            ref={productRow}
          >
            {activeTabData.length !== 0 &&
              activeTabData.map((item, index) => {
                return (
                  <div className="item" key={index}>
                    <Product tag={item.type} item={item} />
                  </div>
                );
              })}
          </div>
        </div>
      </section>
      <section className="topProductsSection">
        <div className="container-fluid">
          <div className="row">
            <div className="col">
              <TopProducts title="Mas vendidos" />
            </div>

            <div className="col">
              <TopProducts title="Mas famosos" />
            </div>

            <div className="col">
              <TopProducts title="Agregados recientemente" />
            </div>

            <div className="col">
              <TopProducts title="Mas valorados" />
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Home;
