import React, { useContext } from "react";
import { ThemeContext } from "../../context/Themes";
import {
  AreaChart,
  Area,
  XAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { TranslatorContext } from "../../context/Translator";
import { useDollar } from "../../hooks/useDollar";

export default function RevenueChartComponent() {
  const { theme } = useContext(ThemeContext);
  const dark = {
    border: "#39496b",
    frame: "#2f3f61",
    title: "#f0f0f0",
    text: "#d1d1d1",
    bg: "#1b2b4d",
  };
  const light = {
    border: "#d1d1d1",
    frame: "#f0f0f0",
    title: "#403e57",
    text: "#5e5d72",
    bg: "#ffffff",
  };
  const { covertFromUsdToDop } = useDollar()
  const { t } = useContext(TranslatorContext);

  const data = [
    { month: "JAN", invested: 4000, earnings: 7400, expenses: 5789 },
    { month: "FEB", invested: 5000, earnings: 8398, expenses: 5789 },
    { month: "MAR", invested: 6000, earnings: 7800, expenses: 5789 },
    { month: "APR", invested: 3780, earnings: 5908, expenses: 5789 },
    { month: "MAY", invested: 7890, earnings: 4800, expenses: 5789 },
    { month: "JUN", invested: 9390, earnings: 5800, expenses: 5789 },
    { month: "JUL", invested: 5390, earnings: 7800, expenses: 5789 },
    { month: "AGU", invested: 6390, earnings: 7800, expenses: 5789 },
    { month: "SEP", invested: 8390, earnings: 5800, expenses: 5789 },
    { month: "OCT", invested: 8390, earnings: 4800, expenses: 5789 },
    { month: "NOV", invested: 4390, earnings: 7800, expenses: 5789 },
    { month: "DEC", invested: 5390, earnings: 6800, expenses: 5789 },
  ];


  const labels = {
    invested: t("invested"),
    earnings: t("earnings"),
    expenses: t("expenses"),
  };


  const formattedData = data.map((item) => ({
    ...item,
    invertido: covertFromUsdToDop(item.invested),
    ganancias: covertFromUsdToDop(item.earnings),
    gastos: covertFromUsdToDop(item.expenses),
  }));

  return (
    <ResponsiveContainer width="100%" aspect={1} maxHeight={385}>
      <AreaChart data={formattedData}>
        <CartesianGrid
          stroke={theme !== "light_mode" ? dark.border : light.border}
          strokeDasharray="1 3"
        />
        <XAxis
          tickSize={0}
          tickMargin={15}
          axisLine={false}
          dataKey="month"
          stroke={theme !== "light_mode" ? dark.text : light.text}
          fontSize="11px"
          fontWeight="500"
          interval="preserveStartEnd"
        />
        <Tooltip
          cursor={false}
          contentStyle={{
            background: `${theme !== "light_mode" ? dark.bg : light.bg}`,
            borderRadius: "8px",
            padding: "11px 18px 12px",
            border: `1px solid ${
              theme !== "light_mode" ? dark.frame : light.frame
            }`,
            boxShadow: "0px 12px 25px 0px rgb(0 0 0 / 12%)",
          }}
          itemStyle={{
            fontSize: "14px",
            fontWeight: "500",
            padding: "2px 0px",
            textTransform: "capitalize",
          }}
          labelStyle={{
            color: `${theme !== "light_mode" ? dark.title : light.title}`,
            fontSize: "12px",
            fontWeight: "600",
            textTransform: "uppercase",
          }}
        />
        <defs>
          <linearGradient id="blueOne" x1="0" y1="0" x2="0" y2="1">
            <stop offset="45%" stopColor="#2351af" stopOpacity={0.25} />
            <stop offset="75%" stopColor="#2351af" stopOpacity={0.03} />
          </linearGradient>
          <linearGradient id="purpleTwo" x1="0" y1="0" x2="0" y2="1">
            <stop offset="45%" stopColor="#8c099f" stopOpacity={0.25} />
            <stop offset="75%" stopColor="#8c099f" stopOpacity={0.03} />
          </linearGradient>
          <linearGradient id="greenThree" x1="0" y1="0" x2="0" y2="1">
            <stop offset="45%" stopColor="#18633a" stopOpacity={0.25} />
            <stop offset="75%" stopColor="#18633a" stopOpacity={0.03} />
          </linearGradient>
        </defs>
        <Area
          type="stepAfter"
          dataKey={labels.invested}
          stackId="1"
          strokeWidth="3"
          stroke="#64b3f6"
          fill="url(#blueOne)"
        />
        <Area
          type="stepAfter"
          dataKey={labels.earnings}
          stackId="1"
          strokeWidth="3"
          stroke="#4eda89"
          fill="url(#greenThree)"
        />
        <Area
          type="stepAfter"
          dataKey={labels.expenses}
          stackId="1"
          strokeWidth="3"
          stroke="#ed68ff"
          fill="url(#purpleTwo)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}
