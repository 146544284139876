import React, { useEffect, useContext } from "react";
import Slider from "react-slick";
import "./index.css";

import Slide1 from "../../../../assets/store/images/slider-1.jpg";
import Slide2 from "../../../../assets/store/images/slider-2.jpg";
import Button from "@mui/material/Button";

import { StoreContext } from "../../../../context/Store";

const HomeSlider = () => {
  const context = useContext(StoreContext);

  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    fade: true,
    arrows: context.windowWidth > 992 ? true : false,
    autoplay: true,
  };

  return (
    <section className="homeSlider">
      <div className="container-fluid position-relative">
        <Slider {...settings} className="home_slider_Main">
          <div className="item">
            <img src={Slide1} className="w-100" />
            <div className="info">
              <h2 className="mb-4">
                No te pierdas las mejores ofertas
                <br />
                En los mejores productos
              </h2>
              <p>Empieza a comprar hoy!!</p>
            </div>
          </div>
          <div className="item">
            <img src={Slide2} className="w-100" />
            <div className="info">
              <h2 className="mb-3">
                Grandes descuentos
                <br />
                en tus prendas favoritas
              </h2>
              <p>Un gran catalogo en la palma de tu mano</p>
            </div>
          </div>
        </Slider>
      </div>
    </section>
  );
};

export default HomeSlider;
