import React from "react";
import PageLayout from "../../layouts/PageLayout";

export default function ChangeLogPage() {
  return (
    <PageLayout>
      <h2 style={{margin: "20px"}} className="text-center">Change Log of Dev</h2>
      <div className="mc-card">
        <div className="mc-alert green">
          <i className="material-icons">campaign</i>
          <h3>
            Update - Version 2.0.1
            <span>diaspo Wholesale Varieties :: Nuevas caracteristicas</span>
          </h3>
        </div>
        <ul className="mc-changelog-list">
          <li>Integración de cálculo automático de dólar a pesos, (tiene cierto margen de error a la hora de calcular) </li>
        </ul>
      </div>
      <div className="mc-card">
        <div className="mc-alert orange">
          <i className="material-icons">campaign</i>
          <h3>
            Update - Version 2.0
            <span>diaspo Wholesale Varieties :: Nuevas caracteristicas</span>
          </h3>
        </div>
        <ul className="mc-changelog-list">
          <li>
            Se ha añadido soporte multilingüe, incluyendo traducción al inglés y
            al español.
          </li>

          <li>
            UI/UX mejorada para tarjetas, relleno, margen, borde, color y más.
          
          </li>
          <li>errores en la traducción en español.</li>
        </ul>
      </div>
    </PageLayout>
  );
}
