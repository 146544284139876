// IMPORTACIONES
import React, { useEffect, useState, createContext } from "react";
// import { RouterProvider } from "react-router-dom";
// import { Provider } from "react-redux";
import axios from "axios";
// STYLOS
import "bootstrap/dist/css/bootstrap.min.css";
import "../App.css";
import "../responsive.css";
//DATA
// import { store } from "./store";
import data from "../data";
//COMPONENTS

const StoreContext = createContext({
  cartItems: null,
  isLogin: false,
  windowWidth: null,
  isOpenFilters: false,
  addToCart: async () => {},
  removeItemsFromCart: async () => {},
  emptyCart: async () => {},
  signOut: async () => {},
  signIn: async () => {},
  openFilters: async () => {},
  isopenNavigation: false,
  setIsopenNavigation: async () => {},
  setCartTotalAmount: async () => {},
  cartTotalAmount: null,
});

const StoreProvider = ({ children }) => {
  const [productData, setProductData] = useState([]);
  const [cartItems, setCartItems] = useState([]);
  const [isLoading, setIsloading] = useState(true);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const [isopenNavigation, setIsopenNavigation] = useState(false);

  const [isLogin, setIsLogin] = useState();
  const [isOpenFilters, setIsopenFilters] = useState(false);
  const [cartTotalAmount, setCartTotalAmount] = useState();

  useEffect(() => {
    // getData('http://localhost:5000/productData');
    // getCartData("http://localhost:5000/cartItems");

    const is_Login = localStorage.getItem("isLogin");
    setIsLogin(is_Login);

    setTimeout(() => {
      setProductData(data[1]);
      setIsloading(false);
    }, 3000);
  }, []);

  const getData = async (url) => {
    try {
      await axios.get(url).then((response) => {
        setProductData(response.data);
        setTimeout(() => {
          setIsloading(false);
        }, 2000);
      });

      await axios
        .get(
          "https://newsapi.org/v2/top-headlines?country=in&category=business&apiKey=27dad2d0abd34a22965727ce8d939077"
        )
        .then((response) => {
          console.log(response);
        });
    } catch (error) {
      console.log(error.message);
    }
  };

  const getCartData = async (url) => {
    try {
      await axios.get(url).then((response) => {
        setCartItems(response.data);
      });
    } catch (error) {
      console.log(error.message);
    }
  };

  const addToCart = async (item) => {
    item.quantity = 1;

    try {
      const response = await axios.post(
        "http://localhost:3006/cartItems",
        item
      );
      if (response.status === 201) {
        // Check for successful creation
        setCartItems([...cartItems, { ...item, quantity: 1 }]);
      } else {
        console.error(`Error adding to cart: ${response.statusText}`);
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const removeItemsFromCart = (id) => {
    const arr = cartItems.filter((obj) => obj.id !== id);
    setCartItems(arr);
  };

  const emptyCart = () => {
    setCartItems([]);
  };

  const signIn = () => {
    const is_Login = localStorage.getItem("isLogin");
    setIsLogin(is_Login);
  };

  const signOut = () => {
    localStorage.removeItem("isLogin");
    setIsLogin(false);
  };

  const openFilters = () => {
    setIsopenFilters(!isOpenFilters);
  };

  const contextValue = {
    cartItems,
    isLogin,
    windowWidth,
    isOpenFilters,
    addToCart,
    removeItemsFromCart,
    emptyCart,
    signOut,
    signIn,
    openFilters,
    isopenNavigation,
    setIsopenNavigation,
    setCartTotalAmount,
    cartTotalAmount,
  };
  return (
    <StoreContext.Provider value={contextValue}>
      {children}
    </StoreContext.Provider>
  );
};

export { StoreProvider, StoreContext };
